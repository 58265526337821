var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"down-top-padding",attrs:{"fluid":""}},[_c('BaseBreadcrumb',{attrs:{"title":_vm.page.title,"icon":_vm.page.icon,"breadcrumbs":_vm.breadcrumbs}}),_c('v-card',[_c('v-card-text',{staticClass:"pa-5"},[_c('v-row'),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"loading":_vm.loading,"server-items-length":_vm.total,"search":_vm.search},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"pill":""}},[_vm._v(_vm._s(item.id))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"content":item.status_title,"label":""}},[_vm._v(_vm._s(item.status_title))])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(_vm.types[item.type]))])]}},{key:"item.promocode",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("/promocodes/edit/" + (item.promocode_id)),"target":"_blank"}},[_vm._v(_vm._s(item.promocode))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("/orders/" + (item.id))}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-eye ")])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }