<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <occupied-alert
              v-if="library && library.id"
              :item-id="library.id"
              prefix="library"
              @change="occupied"
            ></occupied-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h1>
              Редактирование "{{ library.title || library.id }}"
              <v-btn
                icon
                :href="`${apiUrl}/api/module/library/${library.id}/export`"
              >
                <v-icon color="primary">
                  mdi-file-download-outline
                </v-icon>
              </v-btn>
            </h1>
          </v-col>
        </v-row>
        <v-tabs
          v-model="tab"
          background-color="transparent"
          grow
          class="mb-5"
        >
          <v-tab>
            Основная информация
          </v-tab>
          <v-tab>
            Таксономия
          </v-tab>
          <v-tab>
            Старая таксономия
          </v-tab>
          <v-tab>
            SEO
          </v-tab>
          <v-tab>
            SMM
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <library-form
              ref="form"
              :library="library"
              :media="media"
              :errors="errors"
              :is-disabled="isOccupied"
              @onSubmit="submit"
            />
          </v-tab-item>
          <v-tab-item>
            <h4>Сниппет</h4>
            <v-card
              class="mb-2"
              color="grey"
            >
              <div class="container text-center">
                <h1>
                  {{ library.title }}
                </h1>
                <div class="text-center">
                  <a
                    v-if="library.main_taxonomy"
                    href="#"
                    :class="`${library.main_taxonomy.color_code || 'text-1'}`"
                  >
                    {{ library.main_taxonomy.title }},
                  </a>
                  <a
                    v-if="library.main_taxonomy_child"
                    href="#"
                    :class="`${library.main_taxonomy_child.color_code || 'text-1'}`"
                  >
                    {{ library.main_taxonomy_child.title }},
                  </a>
                  <span class="text-secondary">
                    {{ library.published_at  | moment('DD MMMM YYYY') }}
                  </span>
                </div>
              </div>
            </v-card>
            <collection-relation-form
              module-name="Library"
              :errors="errors"
              :selected-collection-items="library.selected_collection_items"
              :main-items="library.main_taxonomy_ids"
              :is-disabled="isOccupied"
              @onSubmit="submitCollections"
              @onSubmitMain="submitCollectionsMainItems"
            />
            <h4>Сниппет</h4>
            <v-card color="grey">
              <div class="container text-center">
                <div
                  v-if="library.taxonomy"
                  class="article-content-tags"
                >
                  Теги:
                  <a
                    v-for="(item, index) in library.taxonomy"
                    href="#"
                    :class="`text-${item.parent && item.parent.color_code ? item.parent.color_code : item.color_code}`"
                    :key="index"
                  >
                    {{ item.title }};
                  </a>
                </div>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <old-taxonomy
              :library="library"
            />
          </v-tab-item>
          <v-tab-item>
            <seo-form
              :entity="library"
              :errors="errors"
              :is-disabled="isOccupied"
              @onSubmit="submit"
            />
          </v-tab-item>
          <v-tab-item>
            <smm-form
              :entity="library"
              :errors="errors"
              :is-disabled="isOccupied"
              @onSubmit="submit"
            />
          </v-tab-item>
        </v-tabs-items>
        <v-snackbar
          v-model="snackbarSuccess"
          :timeout="timeout"
          color="success"
        >
          Сохранено

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarSuccess = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="snackbarError"
          :timeout="timeout"
          color="error"
        >
          {{ this.errors }}

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarError = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import LibraryForm from '../../../components/library/LibraryForm';
import CollectionRelationForm from '../../../components/collections/CollectionRelationForm';
import OldTaxonomy from "../../../components/library/OldTaxonomy";
import SeoForm from "../../../components/SEOForm";
import SmmForm from "../../../components/SMMForm";
import { Library } from '../../../models/library';
import axiosInstance from "../../../axiosInstance";
import OccupiedAlert from '../../../components/occupiedPages/Alert.vue';
import {Media} from "../../../models/media";

export default {
  name: 'Edit',
  components: {
    LibraryForm,
    CollectionRelationForm,
    OldTaxonomy,
    OccupiedAlert,
    SeoForm,
    SmmForm,
  },
  data: () => ({
    library: {},
    media: {},
    errors: {},
    tab: 0,
    snackbarSuccess: false,
    snackbarError: false,
    timeout: 2000,
    isOccupied: false,
    apiUrl: process.env.VUE_APP_API_URL,
    page: {
      title: "Библиотека"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Библиотека",
        disabled: true,
        to: '/library'
      },
      {
        text: "Редактирование",
        disabled: true,
        to: '/library/edit'
      },
    ],
  }),
  created() {
    this.find();
  },
  methods: {
    async submit({ form, media, mediaIds, isNeedRedirect }) {
      try {
        this.errors = {};

        if (mediaIds) {
          form.mediaIds = mediaIds;
        }

        if (media && media.id) {
          await Media.$query().update(media.id, {...media, entity_id: form.id});
        } else if (media) {
          await Media.$query().store({...media, entity_id: form.id});
        }

        await Library.$query().update(form.id, form);
        await this.find();

        this.snackbarSuccess = true;

        if (isNeedRedirect) {
          await this.$router.push({name: 'library-index'});
        }
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors;
          this.snackbarError = true;
        }
        throw e;
      }
    },
    async submitCollections({ items, isNeedRedirect }) {
      try {
        this.errors = {};
        if (items.length > 0) {
          const library = await Library.$query().find(this.$route.params.id);
          await library.collectionItems().sync(items);
          if (isNeedRedirect) {
            this.$router.push({ name: 'library-index' });
          }
          await this.find();
        }
        this.snackbarSuccess = true;
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors;
          this.snackbarError = true;
        }
        throw e;
      }
    },
    async submitCollectionsMainItems(items) {
      try {
        this.errors = {};
        await axiosInstance.post(`/module/library/${this.library.id}/collection-items/make-main`, { items });
        this.snackbarSuccess = true;
        await this.find();
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors;
          this.snackbarError = true;
        }
        throw e;
      }
    },
    async find() {
      const library = await Library.$query()
        .with(['collectionItems'])
        .find(this.$route.params.id);
      this.library = library.$attributes;

      const media = await Media.$query()
        .filter('entity', '=', this.library.entity_type)
        .filter('entity_id', '=', this.library.id).search(1);
      this.media = media && media.length > 0 ? media[0].$attributes : null;
    },
    async makeMain(value) {
      try {
        this.errors = {};
        await axiosInstance.get(`/module/library/${this.library.id}/collection-items/${value}/make-main`);
        this.snackbarSuccess = true;
        await this.find();
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors;
          this.snackbarError = true;
        }
        throw e;
      }
    },
    occupied(value) {
      this.isOccupied = value;
    },
  },
};
</script>
