<template>
  <v-form ref="form" lazy-validation>
    <v-text-field
      v-model="form.title"
      label="Название блока"
      name="title"
      required
      :rules="[rules.required]"
    />
    <v-text-field
      v-model="form.path"
      label="Путь"
      name="path"
    />
    <v-text-field
      v-model="form.component"
      label="Компонент"
      name="path"
      required
      :rules="[rules.required]"
    />
    <v-text-field
      v-model="form.class"
      label="Класс"
      name="class"
      required
      :rules="[rules.required]"
    />
    <v-autocomplete
      v-model="form.setting_id"
      :items="settingsList"
      item-value="id"
      label="Настройки"
      item-text="title"
    />
    <v-select
      v-model="form.block_group_id"
      :items="groupsList"
      item-text="title"
      item-value="id"
      label="Выберите группу"
      name="block_group_id"
      required
      :rules="[rules.required]"
    ></v-select>
    <v-textarea
      v-model="form.description"
      label="Описание"
      name="description"
      required
      :rules="[rules.required]"
    />
    <v-checkbox
      v-model="form.active"
      label="Активен"
    ></v-checkbox>
    <v-checkbox
      v-model="form.is_dynamic"
      label="Является динамическим"
      />
    <media-widget
      v-if="block && block.id"
      class="mb-1"
      label="Анонсное изображение"
      :medias="form.image"
      :is-multiple="false"
      @onChange="setImage"
    />
    <v-card>
      <v-card-title>Структура</v-card-title>
      <div class="ml-2">
        <h4>Вид отображения</h4>
        <v-radio-group row v-model="mode" @change="swithMode" class="mt-0 pt-0">
          <v-radio label="Деревом" value="tree"/>
          <v-radio label="Кодом" value="code"/>
          <v-radio label="Формой" value="form"/>
          <v-radio label="Текстом" value="text"/>
        </v-radio-group>
      </div>
      <v-card-text class="pl-0 pr-0 pb-0">
        <VJsonEditor
          v-model="form.structure"
          :plus="true"
          class="mb-2"
          :options="options"
          ref="jsonEditor"
        />
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>Исключающие ключи</v-card-title>
      <div class="ml-2">
        <h4>Вид отображения</h4>
        <v-radio-group row v-model="mode2" @change="swithMode2" class="mt-0 pt-0">
          <v-radio label="Деревом" value="tree"/>
          <v-radio label="Кодом" value="code"/>
          <v-radio label="Формой" value="form"/>
          <v-radio label="Текстом" value="text"/>
        </v-radio-group>
      </div>
      <v-card-text class="pl-0 pr-0 pb-0">
        <VJsonEditor
          v-model="form.excepted_keys"
          :plus="true"
          class="mb-2"
          :options="options2"
          ref="jsonEditor2"
        />
      </v-card-text>
    </v-card>
    <v-btn
      class="mr-4"
      color="primary"
      @click="submit(true)"
      :disabled="isSaveDisabled"
      :loading="isSaveDisabled"
    >
      Сохранить
    </v-btn>
    <v-btn
      class="mr-4"
      color="primary"
      @click="submit(false)"
      :disabled="isSaveDisabled"
      :loading="isSaveDisabled"
    >
      Сохранить и продолжить
    </v-btn>
    <media-uploader
      v-if="block && block.id"
      :loaded-medias="block.loaded_medias"
      @completeUploads="onCompleteUploads"
    />
  </v-form>
</template>

<script>
import VJsonEditor from 'v-jsoneditor/src/index';
import MediaUploader from '../MediaUploader';
import MediaWidget from '../MediaWidget';
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'BlockForm',
  components: {
    VJsonEditor,
    MediaWidget,
    MediaUploader,
  },
  props: {
    block: {
      type: Object,
      default: () => ({})
    },
  },
  data: () => ({
    form: {
      title: null,
      block_group_id: null,
      structure: null,
      image: null,
      description: null,
      active: 0,
      setting_id: null,
      is_dynamic: false,
      excepted_keys: null,
      class: 'cards\\CA_1',
    },
    options: {
      mode: "tree",
    },
    options2: {
      mode: "tree"
    },
    mode: "tree",
    mode2: "tree",
    mediaIds: [],
    rules: {
      required: value => !!value || 'Обязательное поле',
    },
  }),
  created() {
    this.fetchGroups();
    this.fetchSettings();
    this.setDefault();
  },
  computed: {
    ...mapGetters({
      settingsList: 'BlockSettings/getFetchedList',
      groupsList: 'BlockGroups/getFetchedList',
      isSaveDisabled: 'Blocks/getActionButtonDisabled',
    })
  },
  methods: {
    ...mapActions({
      fetchSettings: 'BlockSettings/fetchList',
      fetchGroups: 'BlockGroups/fetchList',
    }),
    async onCompleteUploads(ids) {
      this.mediaIds = ids;
      await this.submit();
      this.mediaIds = [];
    },
    submit(isNeedRedirect) {
      if(this.$refs.form.validate()){
        this.$emit(
          'onSubmit',
          {
            form: this.form,
            mediaIds: this.mediaIds,
            isNeedRedirect: isNeedRedirect,
          },
        );
      }
    },
    setImage(image) {
      this.form.image = image;
    },
    swithMode() {
      this.$refs.jsonEditor.$options.propsData.options.mode = this.mode;
    },
    swithMode2() {
      this.$refs.jsonEditor2.$options.propsData.options.mode = this.mode2;
    },
    setDefault() {
      if (!this.$route.params.data) {
        this.form.structure = {
          "fields": {},
          "settings": {}
          };
          if(this.settingsList.length)
            this.form.setting_id = this.settingsList[0].id;
      }
    }
  },
  watch: {
    block: {
      handler() {
        if (Object.keys(this.block).length > 0) {
          this.form = JSON.parse(JSON.stringify(this.block));
        }
      },
      immediate: true,
    },
    settingsList: {
      handler() {
        if (!this.$route.params.data) {
          if(this.settingsList.length && !this.form.setting_id)
            this.form.setting_id = this.settingsList[0].id;
        }
      },
      immediate: true,
    },
  },
};
</script>
<style scoped>
.jsoneditor-container{
  height: 800px;
}
</style>
