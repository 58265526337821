<template>
  <v-form>
    <v-row>
      <v-col cols="9">
        <v-card>
          <v-container>
            <v-text-field
              v-model="metaForm.meta_title"
              :error-messages="errors.meta_title"
              label="Meta title"
              name="meta_title"
              required
            ></v-text-field>
            <v-checkbox
              v-model="metaForm.clear_title"
              label="Использовать заголовок без названия сайта"
              name="clear_title"
            ></v-checkbox>
            <v-text-field
              v-model="metaForm.meta_description"
              :error-messages="errors.meta_description"
              label="Meta description"
              name="meta_description"
              required
            ></v-text-field>
            <v-text-field
              v-model="metaForm.meta_keywords"
              :error-messages="errors.meta_keywords"
              label="Meta keywords"
              name="meta_keywords"
              required
            ></v-text-field>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card>
          <v-container>
            <v-checkbox
              v-model="metaForm.show_seo_position"
              label="Добавить запросы для сбора поисковых событий"
              name="show_seo_position"
              required
              @change="$emit('onChangeSeoPositionVision', $event)"
            ></v-checkbox>
            <div>
              Опция позволяет включать и отключать SEO-позиции
            </div>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!hideButton">
      <v-col>
        <div class="seo-preview">
          <h3>Предпросмотр</h3>
          <h4 class="search-system-title"><span>Google</span></h4>
          <google-seo-widget :entity="entity" :form="metaForm" />
          <h4 class="search-system-title"><span>Yandex</span></h4>
          <ya-seo-widget :entity="entity" :form="metaForm" />
        </div>
        <v-flex>
          <v-btn
            class="mr-4"
            color="primary"
            @click="submit"
            :disabled="isSaveDisabled || isDisabled"
            :loading="isSaveDisabled"
          >
            Сохранить
          </v-btn>
        </v-flex>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import YaSeoWidget from './YaSeoWidget.vue';
import GoogleSeoWidget from './GoogleSeoWidget.vue';
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: 'SeoForm',
  components: {
    YaSeoWidget,
    GoogleSeoWidget
  },
  props: {
    entity: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({})
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    formErrors: {},
  }),
  created() {
    if (this.$route.params.data) {
      this.metaForm = JSON.parse(this.$route.params.data);
    }

    if (this.entity) {
      let tempForm = {};

      tempForm.entity_type = this.entity.entity_type || this.entity.entity;
      tempForm.entity_id = this.entity.id;

      this.mutationMetaForm(tempForm);
      this.getMetaData(this.metaForm);
    }
  },
  computed: {
    ...mapGetters({
      metaForm: 'Meta/getMetaForm',
      isSaveDisabled: 'Meta/getIsSaveDisabled',
    }),
  },
  methods: {
    ...mapActions({
      getMetaData: 'Meta/getMetaData',
      storeItem: 'Meta/storeItem',
    }),
    ...mapMutations({
      mutationMetaForm: 'Meta/setMetaForm',
      mutationIsSaveDisabled: 'Meta/setIsSaveDisabled',
    }),
    async submit() {
      this.storeItem({data: this.metaForm});
    },
  },
  watch: {
    entity: {
      handler(entity) {
        if (entity && Object.keys(entity).length > 0) {
          let tempForm = {};

          tempForm = entity;
          tempForm.entity_type = entity.entity_type || entity.entity;

          this.mutationMetaForm(tempForm);
          this.mutationIsSaveDisabled(false);
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped>
  .seo-preview {
    margin-left: 15px;
    margin-right: 15px;
  }

  h4.search-system-title {
    font-size: 14px;
    letter-spacing: 0.015em;
    color: #A3B3CA;
    font-family: "adelle-sans", sans-serif;
    position: relative;
  }

  h4.search-system-title span {
    position: relative;
    background: #fff;
    padding-right: 12px;
  }

  h4.search-system-title:before {
    content: '';
    position: absolute;
    top: 10px;
    right: 0;
    width: 100%;
    height: 1px;
    background: #E0E7F1;
  }
</style>
