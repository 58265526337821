<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <form>
      <validation-provider v-slot="{ errors }" name="Название" rules="required">
        <v-text-field
          v-model="form.title"
          :error-messages="errors"
          label="Название"
        />
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Slug" rules="required">
        <v-text-field
          v-model="form.slug"
          :disabled="form.id ? true : false"
          :error-messages="errors"
          label="slug"
        />
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Артикул">
        <v-text-field
          v-model="form.sku"
          :error-messages="errors"
          label="Артикул"
        />
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Цена">
        <v-text-field
          v-model="form.price"
          type="number"
          label="Цена"
          :error-messages="errors"
          lable="Цена"
        />
      </validation-provider>
      <v-row>
        <v-col v-if="form.dynamic_fields">
          <FieldComponent v-model="form.dynamic_fields" title="Дополнительные поля"/>
        </v-col>
      </v-row>
      <media-widget
        v-if="form.id"
        label="Фото товара"
        :medias="form.image"
        :is-multiple="false"
        @onChange="setImage"
      />
    </form>
    <media-uploader/>
    <v-btn :loading="isLoading" :disabled="isLoading" color="primary" @click="submit(true)">Сохранить</v-btn>
    <v-btn :loading="isLoading" :disabled="isLoading" color="primary" class="ml-2" @click="submit(false)">Сохранить и продолжить</v-btn>
  </validation-observer>
</template>

<script>
import slugify from "slugify";
import MediaUploader from "../MediaUploader";
import MediaWidget from "../MediaWidget";
import { mapGetters } from "vuex";
import FieldComponent from "../../fields/FieldComponent";

export default {
  name: "CatalogItemForm",
  components: {MediaWidget, MediaUploader, FieldComponent},
  data: () => ({
    form: {
      title: '',
      slug: '',
      sku: '',
      price: 0,
      image: null,
    }
  }),
  props: {
    catalogItem: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters({
      isLoading: "CatalogItem/getIsLoading",
    })
  },
  methods: {
    setImage(image) {
      this.form.image = image;
    },
    submit(isNeedRedirect) {
      console.log(this.$refs.observer.validate());
      this.$refs.observer.validate().then((response) => {
        if (response) {
          this.$emit('onSubmit', {
            form: this.form,
            isNeedRedirect: isNeedRedirect,
          })
        }
      });
    }
  },
  watch: {
    'form.title': {
      handler(value) {
        if (value && !this.form.id) {
          this.form.slug = slugify(value.toLowerCase()).match(/[a-z]|[0-9]|[-]/gm).join('');
        }
      }
    },
    catalogItem: {
      handler(value) {
        if (Object.keys(value).length > 0) {
          this.form = JSON.parse(JSON.stringify(value));
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
