<template>
  <v-navigation-drawer
    v-model="Sidebar_drawer"
    :dark="SidebarColor !== 'white'"
    :color="SidebarColor"
    mobile-breakpoint="960"
    clipped
    :right="$vuetify.rtl"
    mini-variant-width="70"
    :expand-on-hover="expandOnHover"
    app
    id="main-sidebar"
    v-bar
  >
    <!---USer Area -->
    <v-list-item v-if="Object.keys(profileImage).length > 0" two-line class="profile-bg" :style="{ 'background-image': 'url(' + profileImage.background_images.simple + ')' }">
      <v-list-item-avatar>
        <img :src="user.avatar_url" />
      </v-list-item-avatar>

      <v-list-item-content class="white--text">
        <v-list-item-title>Привет, {{ user.first_name }}</v-list-item-title>
      <v-list-item-subtitle class="caption white&#45;&#45;text"> {{ user.email }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <!---USer Area -->

    <v-list expand nav class="mt-1">
      <template v-for="(item, i) in items">
        <!---If Sidebar Caption -->
        <v-row v-if="item.header" :key="item.header" align="center">
          <v-col cols="12">
            <v-subheader v-if="item.header" class="d-block text-truncate">{{
              item.header
            }}</v-subheader>
          </v-col>
        </v-row>
        <!---If Sidebar Caption -->
        <BaseItemGroup
          v-else-if="item.children"
          :key="`group-${i}`"
          :item="item"
        ></BaseItemGroup>

        <BaseItem v-else :key="`item-${i}`" :item="item" />
      </template>
      <!---Sidebar Items -->
    </v-list>
    <v-divider></v-divider>
    <!--- Progress -->
<!--    <v-list-item two-line>-->
<!--      <v-list-item-content class>-->
<!--        <v-list-item-title class="d-flex mb-3 align-center">-->
<!--          <span class="body-2 text-truncate">monthly profit</span>-->
<!--          <div class="ml-auto">-->
<!--            <h6 class="mb-0 info&#45;&#45;text">80%</h6>-->
<!--          </div>-->
<!--        </v-list-item-title>-->
<!--        <v-progress-linear rounded value="80"></v-progress-linear>-->
<!--      </v-list-item-content>-->
<!--    </v-list-item>-->
<!--    <v-list-item two-line>-->
<!--      <v-list-item-content class>-->
<!--        <v-list-item-title class="d-flex mb-3 align-center">-->
<!--          <span class="body-2 text-truncate">Sales of the year</span>-->
<!--          <div class="ml-auto">-->
<!--            <h6 class="mb-0 success&#45;&#45;text">54%</h6>-->
<!--          </div>-->
<!--        </v-list-item-title>-->
<!--        <v-progress-linear-->
<!--          color="success"-->
<!--          rounded-->
<!--          value="54"-->
<!--        ></v-progress-linear>-->
<!--      </v-list-item-content>-->
<!--    </v-list-item>-->
    <!--- Progress -->
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import MainMenuItems from "./MainMenuItems";
import axiosInstance from "@/axiosInstance";
export default {
  name: "Vertical-Sidebar",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: [],
    mainMenuItems: MainMenuItems,
  }),
  computed: {
    ...mapState(["SidebarColor", "SidebarBg"]),
    ...mapGetters({
      user: 'auth/getUser',
      profileImage: 'Sites/getPhrasesAndImages',
    }),
    Sidebar_drawer: {
      get() {
        return this.$store.state.Sidebar_drawer;
      },
      set(val) {
        this.$store.commit("SET_SIDEBAR_DRAWER", val);
      },
    },
  },
  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val);
    },
  },
  async created() {
    let defaultVal = {
      title: 'Каталоги',
      group: '/catalog',
      module: 'catalogs',
      model: false,
      icon: 'mdi-store-check',
      children: []
    };

    await axiosInstance.get('module/catalog-menu-items').then((response) => {
      response.data.forEach((item) => defaultVal.children.push(item));
    });

    this.mainMenuItems.push(defaultVal);

    this.filterMenu();
  },
  methods: {
    filterMenu() {
      this.items = this.mainMenuItems.filter((item) => {
        if (item.needAccess && this.can(item.needAccess) && item.module && process.env['VUE_APP_M_' + item.module.toUpperCase()] === "true") {
          return true;
        } else if (!item.needAccess && item.module && process.env['VUE_APP_M_' + item.module.toUpperCase()] === "true") {
          return true;
        } else if (!item.needAccess && !item.module) {
          return true;
        } else if (item.needAccess && this.can(item.needAccess) && !item.module) {
          return true;
        }

        return false
      });
    }
  },
};
</script>
<style lang="scss">
#main-sidebar {
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  .v-navigation-drawer__border {
    display: none;
  }
  .v-list {
    padding: 8px 15px;
  }
  .v-list-item {
    &__icon--text,
    &__icon:first-child {
      justify-content: center;
      text-align: center;
      width: 20px;
    }
  }
  .profile-bg {
    background: url("../../../assets/images/user-info.jpg") no-repeat;
    .v-avatar {
      padding: 45px 0;
    }
  }
}
</style>
