<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row justify="space-between">
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Поиск..."
              filled
              background-color="transparent"
              hide-details
              name="title"
            >
              <v-btn v-if="search" class="reset-button"
                     slot="append"
                     icon
                     @click="search = null"
                     color="red"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-text-field>
          </v-col>
          <v-col>
            <v-switch
              v-model="only_trashed"
              label="Показать удаленные"
              name="only_trashed"
              value="1"
            ></v-switch>
          </v-col>
          <v-col cols="12" lg="4" class="justify-end align-center d-flex">
            <v-dialog v-model="dialog" persistent max-width="700px">
              <template v-slot:activator="{ on }">
              </template>
            </v-dialog>
          </v-col>
          <v-btn
            color="primary"
            dark
            to="/answers/"
            class="mb-2 text-capitalize mr-2"
          >
                Реестр ответов
          </v-btn>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              label="Статусы"
              v-model="filters.status"
              :items="statuses"
            >
              <template v-slot:append-outer>
                <v-btn icon @click="filters.status = null">
                  <v-icon color="red">
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
            </v-select>
          </v-col>
          <v-col>
            <v-select
              label="Типы"
              v-model="filters.type"
              :items="types"
            >
              <template v-slot:append-outer>
                <v-btn icon @click="filters.type = null">
                  <v-icon color="red">
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-btn block color="primary" :href="exportUrl">
            Выгрузить
          </v-btn>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              :options.sync="options"
              :server-items-length="total"
              :search="search"
              :single-select="false"
              v-model="selected"
              show-select
            >
              <template v-slot:item.id="{ item }">
                <v-chip pill>{{ item.id }}</v-chip>
              </template>
              <template v-slot:item.type="{ item }">
                <v-chip :color="getStatusColor(item.type)" :content="item.human_type" label>{{ item.human_type }}</v-chip>
              </template>
              <template v-slot:item.status_title="{ item }">
                <v-chip :color="getStatusColor(item.status)" :content="item.status_title" label>{{ item.status_title }}</v-chip>
              </template>
              <template v-slot:item.changer="{ item }">
                <span v-if="item.changer && item.changer.full_name">{{ item.changer.full_name }}</span>
                <span v-else> - </span>                
              </template>
              <template v-slot:item.comment="{ item }">
                <span v-if="item.comment && item.comment.text">{{ item.comment.text }}</span>
                <span v-else> - </span>                
              </template>
              
              <template v-slot:item.actions="{item}">
                <v-btn
                  :href="editItem(item)"
                  icon
                  small
                >
                  <v-icon color="primary">
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-icon
                  color="red"
                  @click="deleteItem(item)"
                  small
                >
                  mdi-delete
                </v-icon>
              </template>              
            </v-data-table>
            <v-btn block color="primary" @click="deleteItems">
              Удалить отмеченные
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {Lead} from '../../../models/lead';
import axiosInstance from "../../../axiosInstance";

export default {
  name: 'Index',
  data: () => ({
    selected: [],
    search: '',
    loading: true,
    total: 0,
    options: {
      sortBy: ['created_at'],
      sortDesc: [true],
    },
    filters: {
      type: null,
      status: null,
    },
    statuses: [
      {text: 'Новый', value: 0},
      {text: 'Прочитано', value: 1},
      {text: 'Отвечено', value: 2},
    ],
    types: [
      {text: 'Обратная связь', value: 0},
      {text: 'Запрос реестров', value: 1},
      {text: 'Форма сотрудничества', value: 2},
      {text: 'Техническое обращение', value: 3},
    ],
    headers: [
      {text: 'Id', value: 'id'},
      {text: 'ФИО', value: 'name'},
      {text: 'Почта', value: 'email'},
      {text: 'Телефон', value: 'phone'},
      {text: 'Комментарий', value: 'comment'},      
      {text: 'Тип', value: 'type'},
      {text: 'Статус изменен', value: 'changer'},
      {text: 'Статус', value: 'status_title', sortable: false},
      {text: 'Дата создания', value: 'created_at'},
      {text: 'Дата обновления', value: 'updated_at'},
      {text: '', value: 'actions'},
    ],
    items: [],
    page: {
      title: "Лиды"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Лиды",
        disabled: true,
        to: '/leads'
      },
    ],
  }),
  created() {
    this.get();
  },
  computed: {
    exportUrl() {
      const payload = {};
      this.search ? payload.search = this.search : null;
      this.filters.type !== null ? payload.type = this.filters.type : null;
      this.filters.status !== null ? payload.status = this.filters.status : null;

      return process.env.VUE_APP_API_URL + '/api/leads/export?' + (new URLSearchParams(payload)).toString();
    }
  },
  methods: {
    editItem(item) {
      return this.$router.resolve({name: 'leads-edit', params: {id: item.id}}).href
    },
    async deleteItem(item) {
      await Lead.$query().destroy(item.id);
      await this.get();
    },
    deleteItems() {
      const itemsID = this.selected.map(element => element.id);     
      if(itemsID){
        itemsID.forEach(id => {
          Lead.$query().destroy(id);
        });
      }      
      this.selected = [];
      this.get();
    },
    async get() {
      this.loading = true;
      // eslint-disable-next-line no-unused-vars
      const {sortBy, sortDesc, page, itemsPerPage} = this.options;
      const {type, status} = this.filters;
      const onlyTrashed = this.only_trashed ? `&only_trashed=1` : '';
      let data = {filters: []};
      this.search ? data.search = {value: this.search} : null;
      sortBy && sortBy[0] ? data.sort = [{field: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc'}] : null;

      data.filters.push({field: 'comment', operator: '!=', value: null});
      type !== null ? data.filters.push({field: 'type', operator: '=', value: type}) : null;
      status !== null ? data.filters.push({field: 'status', operator: '=', value: status}) : null;


      await axiosInstance.post(`/leads/search?limit=${itemsPerPage}&page=${page}${onlyTrashed}`, data).then((response) => {
        this.items = response.data.data;
        console.log(this.items);
        this.total = response.data.meta.total;
      })
      this.loading = false;
    },
    getStatusColor(status) {
      switch (status) {
        case 0:
          return 'red';
        case 1:
          return 'yellow';
        case 2:
          return 'green';
        default:
          return 'grey';
      }
    },
    getTypeColor(type) {
      switch (type) {
        case 0:
          return 'primary';
        case 1:
          return 'green';
        case 2:
          return 'primary';
        case 3:
          return 'amber';
        default:
          return 'gray';
      }
    },
  },
  watch: {
    options: {
      handler() {
        this.get()
      },
      deep: true,
    },
    search: {
      handler() {
        this.get();
      }
    },
    filters: {
      handler() {
        this.get();
      },
      deep: true,
    },
  }
};
</script>
