var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"down-top-padding",attrs:{"fluid":""}},[_c('BaseBreadcrumb',{attrs:{"title":_vm.page.title,"icon":_vm.page.icon,"breadcrumbs":_vm.breadcrumbs}}),_c('v-card',[_c('v-card-text',{staticClass:"pa-5"},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Поиск...","filled":"","background-color":"transparent","hide-details":"","name":"title"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[(_vm.search)?_c('v-btn',{staticClass:"reset-button",attrs:{"slot":"append","icon":"","color":"red"},on:{"click":function($event){_vm.search = null}},slot:"append"},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1)],1),_c('v-col',[_c('v-switch',{attrs:{"label":"Показать удаленные","name":"only_trashed","value":"1"},model:{value:(_vm.only_trashed),callback:function ($$v) {_vm.only_trashed=$$v},expression:"only_trashed"}})],1),_c('v-col',{staticClass:"justify-end align-center d-flex",attrs:{"cols":"12","lg":"4"}},[(_vm.selectedPromocode)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":_vm.usersImport}},[_vm._v(" Выгрузить в csv ")]):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({name: 'users-create'})}}},[_vm._v(" Создать пользователя ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-select',{attrs:{"items":_vm.promocodes,"label":"Выбрать пользователей промокода","item-text":"title","item-value":"id"},on:{"change":_vm.onSelectPromocode}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.isLoading,"footer-props":{'items-per-page-options':[5, 10, 15, 20, -1]}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"pill":""}},[_vm._v(_vm._s(item.id))])]}},{key:"item.autologin",fn:function(ref){
var item = ref.item;
return [(item.autologin_admin_url)?_c('v-btn',{staticClass:"mr-1",attrs:{"href":item.autologin_admin_url,"color":"primary","small":""}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-login ")]),_vm._v(" Admin ")],1):_vm._e(),(item.autologin_client_url)?_c('v-btn',{staticClass:"mr-1",attrs:{"href":item.autologin_client_url,"color":"primary","small":""}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-login ")]),_vm._v(" Client ")],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"href":_vm.editItem(item),"icon":""}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-icon',{attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }