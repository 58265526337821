<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row justify="space-between">
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Поиск..."
              filled
              background-color="transparent"
              hide-details
              name="title"
            >
              <v-btn v-if="search" class="reset-button"
                     slot="append"
                     icon
                     @click="search = null"
                     color="red"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-text-field>
          </v-col>
          <v-col>
            <v-switch
              v-model="only_trashed"
              label="Показать удаленные"
              name="only_trashed"
              value="1"
            ></v-switch>
          </v-col>
          <v-col cols="12" lg="4" class="justify-end align-center d-flex">
            <v-dialog v-model="dialog" persistent max-width="700px">
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  @click="$router.push({ name: 'forms-create' })"
                >
                  Создать
                </v-btn>
              </template>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="fetchedListing"
              :options.sync="options"
              :server-items-length="listingTotal"
            >
              <template v-slot:item.id="{ item }">
                <v-chip pill>{{ item.id }}</v-chip>
              </template>
              <template v-slot:item.action="{item}">
                <v-btn
                  :href="editItem(item)"
                  icon
                >
                  <v-icon color="primary" small>
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-icon
                  color="red"
                  @click="deleteItem(item)"
                  small
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: "Index",
  data: () => ({
    options: {
      sortBy: ['id'],
      sortDesc: [true],
    },
    search: '',
    total: 0,
    only_trashed: null,
    headers: [
      { text: 'id', value: 'id' },
      { text: 'Название', value: 'title' },
      { text: '', value: 'action' },
    ],
    items: [],
    page: {
      title: "Forms"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Forms",
        disabled: true,
        to: '/forms'
      },
    ]
  }),
  created() {
    this.get();
  },
  computed: {
    ...mapGetters({
      fetchedListing: 'getFetchedListing',
      listingTotal: 'getListingTotalItems',
      isListingLoad: 'isListingLoad',
    })
  },
  methods: {
    ...mapActions({
      fetchListing: 'fetchListing',
      destroyItem: 'destroyItem',
    }),
    async get() {
      const payload = {
        options: this.options,
        onlyTrashed: this.onlyTrashed,
        search: this.search,
      }

      await this.fetchListing(payload);
    },
    async deleteItem(item) {
      this.destroyItem(item.id).then(() => this.get());
    },
    editItem(item) {
      return this.$router.resolve({ name: 'forms-edit', params: { id: item.id } }).href
    }
  },
  watch: {
    only_trashed: {
      handler() {
        this.get();
      }
    },
    search: {
      handler() {
        this.get();
      }
    },
    options: {
      handler() {
        this.get()
      },
      deep: true,
    },
  },
}
</script>

<style scoped>

</style>
